﻿// ==========================================================================
// Copyright (C) 2013 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
//Need to have it in Number Validation
(function ($, k) {
    // We override the default kendo number rule because it allows character after digits.
    // By doing so, kendo.parseFloat as been removed from the new method and currencies symbols will not pass validation, only ',' and '.' are allowed as non digit.
    kendo.ui.validator.rules.mvcnumber = createRule("number");
    
    // This method was taken directly from kendo.aspnetmvc.js since the methode there is not accessible from outside
    function createRule(ruleName) {
        return function (input) {
            if (input.filter("[data-val-" + ruleName + "]").length) {
                // 'number' rule new logic begin
                if (input.val() === "") return true;

                var val = input.val();
                
                var numberRegexp = /^\s*(\+|-)?((\d+((\.|\,)\d+)?)|((\.|\,)\d+))\s*$/;
                return val !== null && numberRegexp.test(val);
                // 'number' rule new logic end
            }
            return true;
        };
    }
})(jQuery, kendo);
// ==========================================================================
// Copyright (C) 2014 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
define("genetec/kendo.override.binding", function () {

    var module = {};
    return module;
});

require(['kendo'], function (kendo) {

    var oldInit = kendo.data.binders.value;

    kendo.data.binders.value = function () {

        oldInit.apply(this, arguments);

        if (this.bindings["value"].get() === 0) {
            this.bindings["value"].set("");
        }
    };

    kendo.data.binders.value.prototype = oldInit.prototype;

    kendo.data.binders.html.prototype.refresh = function () {
        var value = this.bindings.html.get();
        this.element.innerHTML = $.isNumeric(value) ? value.toString() : value;
    };

    //need to have it for Adding Camera 
    var innerText;

    //need to have it for Adding Camera
    (function () {
        var a = document.createElement("a");
        if (a.innerText !== undefined) {
            innerText = "innerText";
        } else if (a.textContent !== undefined) {
            innerText = "textContent";
        }
    })();
    //need to have it for Adding Camera 
    kendo.data.binders.text.prototype.refresh = function () {
        var text = this.bindings.text.get();
        if (text == null) {
            text = "";
        } else if ($.isNumeric(text)) {
            //console.log("here", text);
            text = text.toString();
        }
        this.element[innerText] = text;
    };
    
    var oldChange = kendo.data.binders.input.checked.prototype.change;
    kendo.data.binders.input.checked.prototype.change = function () {
      var oldValue = !this.bindings["checked"].get();
        
      oldChange.apply(this, arguments);
      
        // For checkboxes that "set" doesn't get triggered. 
      this.bindings["checked"].source.trigger('set', { field: this.bindings["checked"].path, value: !oldValue });
      this.bindings["checked"].source.trigger('set', { field: this.bindings["checked"].path, value: oldValue });

    };

    var oldRender = kendo.data.binders.source.fn.render;
    kendo.data.binders.source.fn.render = function () {
        var element = $(this.container()),
            nodeName = this.container().nodeName.toLowerCase(),
            val;

        if (nodeName == "select") {
            val = element.val();
        }

        var returnValue = oldRender.apply(this, arguments);

        if (nodeName == "select") {
            if (element.find("option[value='" + val + "']")) {
                element.val(val);
            }
        }

        return returnValue;
    };

    var oldDestroy = kendo.data.binders.source.fn.destroy;
    kendo.data.binders.source.fn.destroy = function() {
        var source = this.bindings.source.get();

        if (typeof source === "undefined") {
            return;
        }

        return oldDestroy.apply(this, arguments);
    }

    //need to have it for Adding Camera
    kendo.data.binders.addClass = kendo.data.Binder.extend({
        init: function (element, bindings, options) {
            kendo.data.Binder.fn.init.call(this, element, bindings, options);
        },
        refresh: function () {
            var cssClass = this.bindings["addClass"].get();
            if (cssClass) {
                $(this.element).addClass(cssClass);
            }
        }
    });

    //need to have it for Adding Camera 
    kendo.data.binders.visibility = kendo.data.Binder.extend({
        refresh: function () {
            if (this.bindings.visibility.get()) {
                this.element.style.visibility = "";
            } else {
                this.element.style.visibility = "hidden";
            }
        }
    });
    //need to have it for Adding Camera 
    kendo.data.binders.invisibility = kendo.data.Binder.extend({
        refresh: function () {
            if (!this.bindings.invisibility.get()) {
                this.element.style.visibility = "";
            } else {
                this.element.style.visibility = "hidden";
            }
        }
    });

    //need to have it for Adding Camera 
    kendo.data.binders.css = kendo.data.Binder.extend({
        refresh: function () {

            var newCss = this.bindings.css.get(),
                $element = $(this.element),
                realNewCss = {};

            for (var key in newCss) {
                var value = $element.css(key);

                if (value != newCss[key]) {
                    realNewCss[key] = newCss[key];
                }
            }

            $element.css(realNewCss);
        }
    });

    //need to have it for Adding Camera 
    kendo.data.binders.toggleClass = kendo.data.Binder.extend({
        refresh: function () {

            var $element = $(this.element),
                falseClass = $element.attr("data-toggle-class-false"),
                trueClass = $element.attr("data-toggle-class-true");

            if (this.bindings.toggleClass.get()) {
                $element.addClass(trueClass);
                $element.removeClass(falseClass);
            } else {
                $element.addClass(falseClass);
                $element.removeClass(trueClass);
            }
        }
    });

    // Complex type binder that uses "max" and "min" to set the available value range on a View-Model property.
    kendo.data.binders.widget.limits = kendo.data.Binder.extend({
        init: function (widget, bindings, options) {
            kendo.data.Binder.fn.init.call(this, widget, bindings, options);

            this._limits = [];

            // get list of class names from our complex binding path object
            for (var key in this.bindings["limits"].path) {
                this._limits.push({
                    key: key,
                    path: this.bindings["limits"].path[key],
                });
            }

            this._widget = widget;
            this._change = $.proxy(this.change, this);

            // bind the change event to the View-Model.
            this.bindings["value"].source.bind("change", this._change);
        },
        change: function () {

            var that = this,
                model = that.bindings["value"].source,
                property = that.bindings["value"].path;

            if (model.get(property) === undefined || model.get(property) === null || $.trim(model.get(property)) === "" ) {
                return;
            }

            if (model.get(property) > that._widget.options.max) {
                model.set(property, that._widget.options.max);
            }

            if (model.get(property) < that._widget.options.min) {
                model.set(property, that._widget.options.min);
            }
            
        },

        refresh: function () {
            var that = this,
                redraw = false;

            // update the widget options for max and min
            $.each(that._limits, function(index, limit) {
                // set the binder's path to the one for this limit, because this is what .get() acts on.
                that.bindings["limits"].path = limit.path;

                if (limit.key == "max" || limit.key == "min") {
                    if (that.bindings["limits"].get()) {
                        that._widget.options[limit.key] = that.bindings["limits"].get();
                        redraw = true;
                    }
                }
            });
            
            // some widget will need to be recreated, implement the redraw method for those cases.
            if (redraw && $.isFunction(that._widget.redraw)) {
                that._widget.redraw();
            }
        }
    });
    
    //need to have it for Adding Camera 
    kendo.data.binders.widget.options = kendo.data.Binder.extend({
        init: function (widget, bindings, options) {
            kendo.data.Binder.fn.init.call(this, widget.element[0], bindings, options);

            this.widget = widget;
            this._change = $.proxy(this.change, this);
            this.bindings["value"].source.bind("change", this._change);
        },
        change: function () {
            this.refresh();//nducharme: that is weird since in normal usage, refresh is called before change..., you shouldn't need to call refresh manually.
        },

        refresh: function () {

            var options = this.bindings["options"].path,
                value = this.bindings["value"].source;

            for (var prop in options) {
                kendo.setter(prop)(this.widget.options, kendo.getter(options[prop])(value));
            }
        },
        destroy: function () {
            this.bindings["value"].source.unbind("change", this._change);
        }
    });

    //need to have it for Adding Camera 
    kendo.override(kendo.data.binders.visible.fn, "refresh", function() {

        var value = this.context.bindings.visible.get();

        var returnValue = this.parent.apply(this.context, arguments);

        var $element = $(this.context.element);

        if (value && $element.data("focusOnDisplay") == true) {
            $element.focus();
        }

        return returnValue;
    });


});


﻿// ==========================================================================
// Copyright (C) 2012 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
//Rermoving headers for auto refresh 
(function (k, window, $) {
    var oldSetup = k.data.RemoteTransport.fn.setup;
    k.data.RemoteTransport.fn.setup = function (options, type) {
        if (options.data.headers) {
            options.headers = options.data.headers;
            delete options.data['headers'];
        }
        return oldSetup.call(this, options, type);
    };
    //Need it for Adding  EndUser / User 
    window.templatedUrl = function (data) {

        if ($.isFunction(this.url)) {
            return;
        }

        var theUrl = this.url;

        this.url = function (parameters) {
            return k.template(theUrl)(parameters);
        };
    };

    k.override(kendo.data.DataSource.fn, "_params", function () {

        var result = this.parent.apply(this.context, arguments);

        if (this.context._contextFilters) {

            if (!result.filter) {
                result.filter = {
                    filters: [],
                    logic: "and"
                };
            }

            $.each(this.context._contextFilters, function () {

                var that = this;

                var filter = result.filter.filters.filter(function (item) {
                    //return item.
                    return that.field == item.field;
                });

                if (!filter.length) {
                    result.filter.filters.push(that);
                }
            });
        }

        return result;
    });

    k.override(kendo.data.DataSource.fn, "_readData", function () {

        var result = this.parent.apply(this.context, arguments);

        this.context._contextFilters = arguments[0].Filters;

        //field: "filterByGuids"
        //operator: "contains"
        //value: "89|1,1|2"

        return result;
    });

    k.override(kendo.data.DataSource.fn, "_cancelModel", function () {

        var model = arguments[0];
        var pristine = this.context._pristineForModel(model);

        this.context._eachItem(this.context._data, function (items) {
            var idx = indexOfModel(items, model);
            if (idx >= 0) {
                var isNewFromExisting = $.isFunction(model.isNewFromExisting) && model.isNewFromExisting();
                if (pristine && ((!model.isNew() && !isNewFromExisting) || pristine.__state__)) {
                    items[idx].accept(pristine);
                } else {
                    items.splice(idx, 1);
                }
            }
        });
    });

    function indexOfModel(data, model) {
        if (model) {
            return indexOf(data, function (item) {
                return item.uid == model.uid;
            });
        }
        return -1;
    }

    function indexOf(data, comparer) {
        var idx, length;

        for (idx = 0, length = data.length; idx < length; idx++) {
            if (comparer(data[idx])) {
                return idx;
            }
        }

        return -1;
    }


})(kendo, window, jQuery);
// ==========================================================================
// Copyright (C) 2012 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
define("genetec/kendo.editable", ["jquery", "genetec/widgets/messagebox"], function ($, messageBox) {

    var module = {};
    
    function _showMessage(container, name, errors) {
        var field = $("[name='" + name + "']", container);

        if (field.length == 0) {
            return false;
        }

        field.addServerSideError(errors[0]);
        return true;
    }

    function _showValidationErrors(editable, errors) {

        var errorMessages = "";

        for (var error in errors) {
            if (!_showMessage(editable.element, error, errors[error].errors)) {
                errorMessages += errors[error].errors[0] + "\r\n";
            }
        }

        if (errorMessages != "") {
            messageBox.show({ content: errorMessages });
        }

        var $wizard = $(".wizard", editable.element),
            wizard = $wizard.data("genetec_wizard") || $wizard.data("kendoWizard2");
        
        wizard.showFirstPageWithError();
    };

    module.showValidationErrors = _showValidationErrors;
    return module;
});

require(['kendo', 'jquery'], function (kendo, $) {
    var errorTemplate = '<span class="g-validationWrapper"><div><div class="k-widget k-tooltip k-tooltip-validation" style="margin:0.5em"><table><tr><td style="vertical-align: top;"><span class="k-icon k-warning" title="#=message.replace("\\\"", "&\\#34;")#"></span></td><td><div style="text-align:left;line-height: 18px;">#=message#</div></td></tr></table><div class="k-callout k-callout-w"></div></div></div></span>';

    kendo.ui.Editable.prototype.options.errorTemplate = errorTemplate;

    var oldKendoEditable = $.fn.kendoEditable;

    $.fn.kendoEditable = function () {

        $.each(this, function () {
            $(this).on("keyup blur cutted pasted", ":input", function () {
                var that = $(this);

                var editable = that.parents(":hasData(kendoEditable):first").data("kendoEditable");

                if (!that[0].kendoBindingTarget) {
                    return;
                }

                //It may not always be the value binding, 
                //but we assume that the value binding is the last one as it's injected after the code.
                var valueBinderPosition = that[0].kendoBindingTarget.toDestroy.length - 1;
                var modelValuePath = that[0].kendoBindingTarget.toDestroy[valueBinderPosition].path;
                var modelValue = editable.options.model.get(modelValuePath);
                
                //if the value must be calculated in a function then the parent property must have been defined.
                if ($.isFunction(modelValue) && modelValue.parent) {
                    modelValue = modelValue.call(editable.options.model);
                }

                if ((that.val() == modelValue || !modelValue) && (that.hasClass("k-invalid-msg") || that.hasClass("k-invalid"))) {
                    editable.validatable.validateInput(this);
                }
            });
        });

        var returnValue = oldKendoEditable.apply(this, arguments);

        //$(returnValue).each(function() {

        //    var editable = $(this).data('kendoEditable');

        //    var oldRefresh = editable.refresh;

        //    editable.refresh = function() {

        //        var that = this;

        //        var validator = that.validatable;

        //        //if (validator && validator.destroy) {
        //            validator.destroy();
        //        //}

        //        return oldRefresh.apply(this, arguments);
        //    };

        //});

        return returnValue;
    };

    var oldEnd = kendo.ui.Editable.prototype.end;

    $.extend(kendo.ui.Editable.prototype, {

        _validate: function (e) {
            var that = this,
                isBoolean = typeof e.value === "boolean",
                input,
                preventChangeTrigger = that._validationEventInProgress,
                values = {};

            values[e.field] = e.value;

            var inputs = $(":input[" + kendo.attr("bind") + "]");

            //Filter out anything that is not bound
            inputs = inputs.filter(function () { return this.kendoBindingTarget; });

            //Extract the element and its binding
            inputs = inputs.map(function () {
                return {
                    item: this,
                    binding: $(this.kendoBindingTarget.toDestroy).filter(function () { return this instanceof kendo.data.binders.value || this instanceof kendo.data.binders.select.value || this instanceof kendo.data.binders.input.checked; }).get(0)
                };
            }).filter(function () {
                if (this.binding) {
                    return true;
                }

                return false;
            });

            //Find the right input with its path
            input = inputs.filter(function () {
                if (this.binding.bindings.hasOwnProperty("checked")) {
                    var me = this.binding.bindings.checked.path == e.field.replace(/_/g, ".");
                    return me;
                }
                
                return this.binding.bindings.value.path == e.field.replace(/_/g, ".");
            }).map(function () {
                return this.item;
            });

            try {
                that._validationEventInProgress = true;

                if (!that.validatable.validateInput(input) || (!preventChangeTrigger && that.trigger("change", { values: values }))) {
                    //Preventing default causes more problems then it solves. It will in the end never put the invalid value in the 
                    //model, but it has the side effect that the old value is kept there and when you select it back, it won't trigger a change.
                    //e.preventDefault();
                }

            } finally {
                that._validationEventInProgress = false;
            }
        }
    });
});


﻿//(function(k) {


//    var oldAnimate = k.fx.animate;

//    k.fx.animate = function (elements, properties, options) {

//        var oldComplete = options.complete;

//        options.complete = function() {
//            if ($(this).css("-webkit-transform") == "matrix(1, 0, 0, 1, 0, 0)") {
//                $(this).css("-webkit-transform", "");
//            }

//            if (oldComplete !== null && oldComplete !== undefined) {
//                return oldComplete.apply(this, arguments);
//            }

//            return;
//        };

//        return oldAnimate.call(this, elements, properties, options);

//    };

//})(kendo);
// ==========================================================================
// Copyright (C) 2017 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

define("genetec/kendo.grid", ["jquery", "genetec/kendo.validation", "kendo", "genetec/widgets/timer"], function ($, gKendoValidation, kendo, timer) {
    var gridKey = "kendoGrid";
    var module = {};

    function _autoRefresh(options) {
        var that = this;
        var gOptions = require("options");

        that.Options = $.extend({}, that.defaultRefreshOptions, options);
        that.Options.IsRefreshNeededByState = options.IsRefreshNeededByState;

        var refreshInterval = that.Options.RefreshIntervalForGrid || gOptions.Constants.refreshIntervalForGrid;

        that._Timer = timer.create({
            Callback: function () { that._refreshGrid(that); },
            Timeout: refreshInterval
        });

        var objGrid = $(that.Options.Grid);
        var grid = objGrid.data(gridKey);

        grid.bind("dataBound", function () {
            grid.dataSource._filter = null;
            that._Timer.Cancel();
            that._checkForGridRefresh();
        });
    };

    $.extend(_autoRefresh.prototype, {
        _getGuids: function () {
            var result = "";
            var that = this;
            var objGrid = $(that.Options.Grid);
            var grid = objGrid.data(gridKey);
            var dataSource = grid.dataSource;
            $(dataSource.data()).each(function () {
                result = result.concat(this[that.Options.GuidColumnName] + ",");
            });
            var lastComma = result.lastIndexOf(",");
            return result.substring(0, lastComma);
        },
        _refreshGrid: function (that) {
            var objGrid = $(that.Options.Grid);
            var grid = objGrid.data(gridKey);
            if (grid.editable == undefined || grid.editable == null) {
                if (that.Options.IsRefreshNeededByState()) {
                    var guids = that._getGuids();

                    grid.dataSource.query({
                        page: grid.dataSource.page(),
                        pageSize: grid.dataSource.pageSize(),
                        filter: [{ field: "filterByGuids", operator: "contains", value: guids }],
                        group: [],
                        sort: {},
                        headers: { "g-session-skip-sliding": that.Options.SessionSkipSliding }
                    });
                }
            }
        },
        _checkForGridRefresh: function () {
            var that = this;
            if (that.Options.IsRefreshNeededByState()) {
                that._Timer.Start();
            }
        },
        defaultRefreshOptions: {
            Grid: null,
            GuidColumnName: 'AccountId',
            SessionSkipSliding: true
        }
    });

    function _onCheckValidationError(gridSelector, args) {
        if (args.errors) {
            args.preventDefault();

            var gridElement = $(gridSelector);
            var grid = gridElement.data(gridKey);

            grid.one("dataBinding", function (e) {
                e.preventDefault(); // cancel grid rebind if error occurs

                if (!grid.editable) {
                    grid._editable();
                }

                gKendoValidation.applyServerSideError(grid.editable.element, args);

                var wiz = $(".wizard", grid.editable.element).wizard().get(0);
                wiz.showFirstPageWithError();
                wiz.actionBar.updateButtonStates();
            });
        }
    };

    function _setupAutoRefreshFiltering(gridSelector, refreshOnEmpty) {
        var grid = $(gridSelector).data("kendoGrid");

        var oldTransport = grid.dataSource.transport.parameterMap;
        grid.dataSource.transport.parameterMap = function (options, operation) {
            if (operation === "read") {
                var that = $(gridSelector);
                var textBoxFilter = $(".filterContainer #filter", that);

                var newFilters = new Array();
                var counter = 0;
                $(options.filter).each(function () {
                    newFilters[counter] = this.filters[counter];
                    counter++;
                });
                newFilters[counter] = { field: "filter", operator: "eq", value: textBoxFilter.val() };

                if (!options.filter) {
                    options.filter = { logic: 'and' };
                }

                options.filter.filters = newFilters;
            }

            return oldTransport.call(this, options, operation);
        };

        $(document).ready(function () {
            var that = $(gridSelector);
            var grid = that.data(gridKey);

            if (grid.dataSource.data().length > 0 || refreshOnEmpty) {
                grid.dataSource.fetch();
            }

            var textBoxFilter = $(".filterContainer #filter", that);
            var filterButton = $(".filterContainer a", that);

            textBoxFilter.bind("keypress", function (e) {
                if (e.which === 13) {
                    _launchFetch(grid);
                }
            });

            filterButton.click(function (e) {
                e.preventDefault();
                _launchFetch(grid);
            });
        });

        function _launchFetch(g) {
            g.dataSource.page(1);
        }
    }

    function _onRequestEnded(gridSelector, createNewBtnSelector, args) {
        var thatcreateNewBtnSelector = createNewBtnSelector;
        var grid = $(gridSelector).data(gridKey);

        if (args.type === "read") {
            if (window.location.hash === '#operation=add') {
                //Bind to the event once, no more.
                grid.one('dataBound', function () {
                    $(thatcreateNewBtnSelector).click();
                });

                window.location.hash = '';
            }
        }
    }

    function _onFinishWizard() {
        var wizard = this,
            container = wizard.container || wizard.element;

        $('.k-grid-update', container.parents('.k-window:first')).click();
        $('.k-update-button', container.parents('.k-window:first')).click();
    }

    function _onWizardInit() {
        var wizard = this;

        var popup = $(wizard.container).parents('.k-window:first');
        $('.k-grid-update,.k-grid-cancel', popup).hide();
        $('.k-update-button,.k-cancel-button', popup).hide();
        $(wizard.container).parents("[data-role=window]:first").data("kendoWindow").center();
    }

    module.AutoRefresh = _autoRefresh;
    module.onCheckValidationError = _onCheckValidationError;
    module.setupAutoRefreshFiltering = _setupAutoRefreshFiltering;
    module.onRequestEnded = _onRequestEnded;
    module.onFinishWizard = _onFinishWizard;
    module.onWizardInit = _onWizardInit;
    return module;
});

require(["jquery"], function ($) {
    var oldKendoGrid = $.fn.kendoGrid;

    var oldBeforeAddRow = kendo.ui.Grid.prototype.addRow;

    kendo.ui.Grid.prototype.addRow = function() {
        if (!this.trigger("beforeAddRow")) {
            return oldBeforeAddRow.apply(this, arguments);
        }

        return undefined;
    };

    // Override functions for kendo's VirtualScrollable widget
    var kendoVirtualScrollable = 'VirtualScrollable';
    var oldWrap = kendo.ui[kendoVirtualScrollable].prototype.wrap;
    var oldRefresh = kendo.ui[kendoVirtualScrollable].prototype.refresh;

    kendo.ui[kendoVirtualScrollable].prototype.wrap = function() {
        oldWrap.apply(this, arguments);

        var element = this.element;
        element.css('padding-left', '');
        element.css('padding-right', '');

        element.find('.k-scrollbar.k-scrollbar-vertical').css('width', '');
    };

    kendo.ui[kendoVirtualScrollable].prototype.refresh = function() {
        var that = this;

        // This delay is needed for the IE(!) to pick up the correct height
        setTimeout(function() {
            oldRefresh.apply(that, arguments);
        }, 300);
    };

    $.fn.kendoGrid = function () {
        var returnValue = oldKendoGrid.apply(this, arguments);

        $.each(returnValue, function () {
            var gridHeader = $(this).find('.k-grid-header');
            gridHeader.css('padding-left', '');
            gridHeader.css('padding-right', '');

            $(this).data("kendoGrid").bind("edit", function () {
                var that = this;
                that.editable.element.data("kendoWindow").bind("activate", function () {
                    that.editable.element.find(":focusable:first").focus();
                });
            });
        });

        return returnValue;
    };
});

// ==========================================================================
// Copyright (C) 2017 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

define("genetec/kendo.listview", ["jquery", "genetec/kendo.validation", "kendo", "genetec/widgets/timer", "genetec/widgets/messagebox"], function ($, gKendoValidation, kendo, timer, messagebox) {
    var listViewKey = "kendoListView";
    var module = {};

    require(["genetec/kendo.pager"]);

    function _autoRefresh(options) {
        var that = this;
        var gOptions = require("options");

        that.Options = $.extend({}, that.defaultRefreshOptions, options);
        that.Options.IsRefreshNeededByState = options.IsRefreshNeededByState;

        var refreshInterval = that.Options.RefreshIntervalForListView || gOptions.Constants.refreshIntervalForListView;

        that._Timer = timer.create({
            Callback: function () { that._refreshListView(that); },
            Timeout: refreshInterval
        });

        var objListView = $(that.Options.ListView);
        var listView = objListView.data(listViewKey);

        listView.bind("dataBound", function () {
            listView.dataSource._filter = null;
            that._Timer.Cancel();
            that._checkForListViewRefresh();
        });
    };

    $.extend(_autoRefresh.prototype, {
        _getGuids: function () {
            var result = "";
            var that = this;
            var objListView = $(that.Options.ListView);
            var listView = objListView.data(listViewKey);
            var dataSource = listView.dataSource;
            $(dataSource.data()).each(function () {
                result = result.concat(this[that.Options.GuidColumnName] + ",");
            });
            var lastComma = result.lastIndexOf(",");
            return result.substring(0, lastComma);
        },
        _refreshListView: function (that) {
            var objListView = $(that.Options.ListView);
            var listView = objListView.data(listViewKey);
            if (listView.editable == undefined || listView.editable == null) {
                if (that.Options.IsRefreshNeededByState()) {
                    var guids = that._getGuids();
                    if (guids !== "") {
                        listView.dataSource.query({
                            page: listView.dataSource.page(),
                            pageSize: listView.dataSource.pageSize(),
                            filter: [{ field: "filterByGuids", operator: "contains", value: guids }],
                            group: [],
                            sort: {},
                            headers: { "g-session-skip-sliding": "true" }
                        });
                    }
                }
            }
        },
        _checkForListViewRefresh: function () {
            var that = this;
            if (that.Options.IsRefreshNeededByState()) {
                that._Timer.Start();
            }
        },
        defaultRefreshOptions: {
            ListView: null,
            GuidColumnName: 'AccountId'
        }
    });

    function _setupAutoRefreshFiltering(listViewSelector) {
        var listView = $(listViewSelector).data("kendoListView");

        var oldTransport = listView.dataSource.transport.parameterMap;
        listView.dataSource.transport.parameterMap = function (options, operation) {
            if (operation === "read") {
                var textBoxFilter = $(".filterContainer #filter");

                var newFilters = new Array();
                $(options.filter).each(function () {
                    newFilters.push.apply(newFilters, this.filters);
                });
                newFilters.push({ field: "filter", operator: "eq", value: textBoxFilter.val() });

                if (!options.filter) {
                    options.filter = { logic: 'and' };
                }

                options.filter.filters = newFilters;
            }

            return oldTransport.call(this, options, operation);
        };
    }

    function _launchFetch(list) {
        list.dataSource.page(1);
    }

    function _onCheckValidationError(listViewSelector, args) {
        if (args.errors) {
            args.preventDefault();

            var listviewElement = $(listViewSelector);
            var listview = listviewElement.data(listViewKey);

            listview.one("dataBinding", function (e) {
                e.preventDefault(); // cancel grid rebind if error occurs

                gKendoValidation.applyServerSideError(listview.editable.element, args);

                var wiz = $(".wizard", listview.editable.element).wizard().get(0);
                if (wiz) {
                    wiz.showFirstPageWithError();
                    wiz.actionBar.updateButtonStates();
                }
            });
        }
    };

    function _initHeader() {
        var listview = $(".k-listview:visible");
        var list = listview.data(listViewKey);

        var createButton = $(".createActionContainer a");

        var textBoxFilter = $(".filterContainer #filter");
        var filterButton = $(".filterContainer a");

        createButton.on("click", function (e) {
            if (!_isMaximumNumberOfItemsReached(list)) {
                list.add();
            } else {
                messagebox.show({ content: list.options.maxItemReachedMessage });
            }
        });

        textBoxFilter.bind("keypress", function (e) {
            if (e.which === 13) {
                _launchFetch(list);
            }
        });

        filterButton.click(function () {
            _launchFetch(list);
        });
    }

    function _isMaximumNumberOfItemsReached(list) {
        var listItems = list.dataSource;

        if (listItems === undefined) {
            throw new ReferenceError("listItems was undefined.");
        }

        return (listItems.data().length > 0 && listItems.total() + 1 >= listItems.data()[0].MaximumNumberOfItems);
    }

    function _init() {
        // It seems that Kendo needs this method, even if it's empty
    }

    module.init = _init;
    module.initHeader = _initHeader;
    module.AutoRefresh = _autoRefresh;
    module.onCheckValidationError = _onCheckValidationError;
    module.setupAutoRefreshFiltering = _setupAutoRefreshFiltering;
    return module;
});

(function ($, kendo) {
    var defaultCommands = {
        cancel: {
            text: "Cancel changes",
            imageClass: "k-cancel",
            className: "k-cancel-button",
            iconClass: "k-icon"
        },
        destroy: {
            text: "Delete",
            imageClass: "k-delete",
            className: "k-delete-button",
            iconClass: "k-icon"
        },
        edit: {
            text: "Edit",
            imageClass: "k-edit",
            className: "k-edit-button",
            iconClass: "k-icon"
        },
        update: {
            text: "Update",
            imageClass: "k-update",
            className: "k-update-button",
            iconClass: "k-icon"
        }
    };

    var oldKendoListView = $.fn.kendoListView;
    $.fn.kendoListView = function () {
        var returnValue = oldKendoListView.apply(this, arguments);

        $.each(returnValue, function () {
            var that = $(this);
            var data = that.data();
            var listView = that.data("kendoListView");
            var options = listView.options;

            options.createTemplate = null;
            options.createTitle = null;
            options.editTemplate = null;

            for (var property in data) {

                if (property.indexOf("listview") !== 0) {
                    continue;
                }

                var propertyName = property.replace("listview", "");
                propertyName = propertyName.substring(0, 1).toLowerCase() + propertyName.substring(1, propertyName.length);

                options[propertyName] = data[property];
            }

            if (!options.editMode) {
                options.editMode = "popup";
            }

            that.data("kendoListView").bind("edit", function () {
                var that = this;
                that.editable.element.data("kendoWindow").bind("activate", function () {
                    that.editable.element.find(":focusable:first:not(:button)").focus();
                });
            });
        });

        return returnValue;

    };


    $.extend(kendo.ui.ListView.prototype, {
        _createButton: function (command) {
            var template = command.template || '<a class="k-button k-button-icontext #=className#" #=attr# href="\\#"><span class="#=iconClass# #=imageClass#"></span>#=text#</a>',
                commandName = typeof command === "string" ? command : command.name || command.text,
                options = { className: "k-" + (commandName || "").replace(/\s/g, "") + "-button", text: commandName, imageClass: "", attr: "", iconClass: "" };

            if (!commandName && !($.isPlainObject(command) && command.template)) {
                throw new Error("Custom commands should have name specified");
            }

            if ($.isPlainObject(command)) {
                if (command.className) {
                    command.className += " " + options.className;
                }

                if (commandName === "edit" && $.isPlainObject(command.text)) {
                    command = $.extend(true, {}, command);
                    command.text = command.text.edit;
                }

                options = $.extend(true, options, defaultCommands[commandName], command);
            } else {
                options = $.extend(true, options, defaultCommands[commandName]);
            }

            return kendo.template(template)(options);
        },
        _closeEditable: function (validate, isCancel) {
            var that = this,
                editable = that.editable,
                data,
                index,
                template = that.template,
                valid = true;

            if (editable) {
                if (validate) {
                    valid = editable.end();
                }

                if (valid) {

                    data = that._modelFromElement(editable.element);
                    index = that.dataSource.indexOf(data);

                    if (index % 2) {
                        template = that.altTemplate;
                    }

                    that._destroyEditable();

                    if (!isCancel) {
                        that.items().eq(index).replaceWith(template(data));
                        that.items().eq(index).attr(kendo.attr("uid"), data.uid);
                    }
                }
            }

            return valid;
        },
        _onDataSourceError: function (args) {
            if (args.errors) {
                args.preventDefault();

                var that = this;

                that.one("dataBinding", function (e) {
                    e.preventDefault(); // cancel grid rebind if error occurs

                    require(["genetec/kendo.validation"], function(gKendoValidation) {
                        gKendoValidation.applyServerSideError(that.editable.element, args);

                        var wiz = that.editable.element.find(":hasData(kendoWizard2)").data("kendoWizard2");
                        if (wiz) {
                            wiz.showFirstPageWithError();
                        }
                    });
                });
            }
        },
        _ondataSourceErrorProxy: function() {
            if (this.__ondataSourceErrorProxy) {
                return this.__ondataSourceErrorProxy;
            }

            this.__ondataSourceErrorProxy = $.proxy(this._onDataSourceError, this);

            return this.__ondataSourceErrorProxy;
        },
        _createPopup: function (item, model, opt) {
            var that = this,
                html = '<div ' + kendo.attr("uid") + '="' + model.uid + '"><div class="k-edit-form-container">',
                fields = [],
                updateText = "",
                cancelText = "",
                editable = that.options.editable,
                options = $.isPlainObject(editable) ? editable.window : {},
                settings = $.extend({}, kendo.Template, that.options.templateSettings),
                template,
                windowTitle;

            that.dataSource.unbind("error", that._ondataSourceErrorProxy());
            that.dataSource.bind("error", that._ondataSourceErrorProxy());

            if (!model.isNew() || model.IsReplacing) {
                template = opt.editTemplate;
                windowTitle = opt.editTitle;
            }

            if (model.isNew() || (!template && !model.isNew()) || (model.IsEditable === false && !model.IsReplacing)) {
                template = opt.createTemplate;
                windowTitle = opt.createTitle;
            }

            if (template) {
                if (typeof template === "string") {
                    if (/^([a-z0-9])+$/gi.test(template)) {
                        //if it is a single word, we will try to get a script element as the template content.
                        var scriptElement = $("script#" + template);

                        if (scriptElement.length === 1) {
                            template = scriptElement.html();
                        }
                    }

                    template = window.unescape(template);
                }

                html += (kendo.template(template, settings))(model);
            } else {
                throw new Error("You need to specify a template.");
            }

            html += that._createButton({ name: "update", text: updateText });
            html += that._createButton({ name: "cancel", text: cancelText });

            html += '</div></div>';

            var container = that._editContainer = $(html)
                .appendTo(item).eq(0)
                .kendoWindow($.extend({
                    modal: true,
                    resizable: false,
                    draggable: true,
                    title: windowTitle,
                    visible: false,
                    close: function (e) {
                        if (e.userTriggered) {
                            if (that.trigger("cancel", { container: container, model: model })) {
                                e.preventDefault();
                                return;
                            }
                            that.cancel();
                        }
                    }
                }, options));

            that.editable = that._editContainer
                .kendoEditable({
                    fields: fields,
                    model: model,
                    clearContainer: false
                }).data("kendoEditable");

            container.data("kendoWindow").center().open();

            that.trigger("edit", { container: container, model: model });
        },
        edit: function (item) {
            var that = this;

            var model = that._modelFromElement(item);
            var oldModel = jQuery.extend(true, {}, model);

            model.oldModel = function () {
                return oldModel;
            };

            if (that.trigger("beforeEdit", { model: model })) {
                return;
            }

            this._createPopup(item, model, this.options);

            //Lets create the handlers, we will need to
            var elem = this.element;
            this.element = this._editContainer;
            this._crudHandlers();
            this.element = elem;
        },
        save: function () {
            var that = this,
                editable = that.editable,
                model;

            if (!editable) {
                return;
            }

            editable = editable.element;
            model = that._modelFromElement(editable);

            if (!that.trigger("save", { model: model, item: editable }) && editable.end()) {
                that.dataSource.sync();
            }
        },
        cancel: function () {
            var that = this,
                dataSource = that.dataSource;

            if (that.editable) {
                var container = that.editable.element;
                var model = that._modelFromElement(container);

                if (!that.trigger("cancel", { model: model, container: container })) {
                    that._closeEditable(false, true);
                    dataSource.cancelChanges(model);
                    that.trigger("canceled", { model: model, container: container });
                }
            }
        }
    });
})(jQuery, kendo);

// ==========================================================================
// Copyright (C) 2012 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

//This File is needed to change the Grid's pager style sheet

define("genetec/kendo.pager", [], function () {

    var module = {};

    return module;
});

require(["jquery", "kendo"], function(jQuery, kendo) {

    function _wrapIt(pagerElement) {
        pagerElement.children().wrapAll("<div class='pagerContent' />");
    }

    var oldPager = kendo.ui.Pager;

    kendo.ui.Pager = oldPager.extend({
        init: function() {
            oldPager.prototype.init.apply(this, arguments);

            _wrapIt(this.element);
        }
    });

    $(":hasData(kendoPager)").each(function() {
        _wrapIt($(this));
    });

});
// ==========================================================================
// Copyright (C) 2012 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
(function ($, k) {

    var oldShouldSerialize = kendo.data.ObservableObject.prototype.shouldSerialize;

    $.extend(k.data.ObservableObject.prototype, {
        resetTo: function (value) {
            this.isResettingModelTo = true;

            var reset = $.proxy(recursiveResetTo, this);

            reset(value, "Max", false);
            reset(value, "Max", true);

            if (this.hasOwnProperty("isResettingModelTo")) {
                delete this.isResettingModelTo;
            }
        },
        setWithoutFail: function (fieldName, value) {
            k.setter(fieldName)(this, value);
            this.trigger('change', { field: fieldName });
            this.trigger('set', { field: fieldName, value: value });
        },
        shouldSerialize: function (field) {

            if (field == "_client") {
                return false;

            }

            return oldShouldSerialize.apply(this, arguments);
        }
    });

    var recursiveResetTo = function(value, priorityPrefix, skip) {
        for (var property in value) {
            if (this[property] instanceof k.data.ObservableObject) {
                this[property].resetTo(value[property]);
            }
            else {
                if (property.indexOf(priorityPrefix) === 0) {
                    if (!skip) {
                        this.set(property, value[property]);
                    }
                } else {
                    this.set(property, value[property]);
                }
            }
        }
    }

    //need to have it in Adding a new device
    var cachedCalculatedProperty = kendo.data.ObservableObject.extend({
        init: function (func) {
            var that = this;
            kendo.data.ObservableObject.fn.init.call(this);

            this.func = func;
            this.oldValues = null;

            that._access = $.proxy(function (e) {
                this._fields.push(e.field);
            }, that);

            that.value = function () {
                return that._valueAccessor(this);
            };

        },
        _valueAccessor: function (source) {
            var that = this;

            if (!that.hasChanged(source)) {

                for (var i = 0; i < that._fields.length; i++) {
                    source.get(that._fields[i]);
                }

                return that._value;
            }

            that._start(source);
                
            var arg = {},
                returnValue = that.func.apply(source, [arg].concat(Array.prototype.slice.call(arguments, 0)));

            that._stop(source);

            that.gatherValues(arg.requiresRefresh, source);

            that._value = returnValue;

            return returnValue;
        },
        _start: function (model) {
            this._fields = [];
            model.bind("get", this._access);
        },
        _stop: function (model) {
            model.unbind("get", this._access);
        },
        hasChanged: function (model) {
            return !_equal(this._values, model);
        },
        gatherValues: function (requiresRefresh, model) {

            var values = {},
                that = this;

            if (requiresRefresh == true) {
                that._values = null;
                return;
            }

            $.each(that._fields, function (index, property) {
                _assignSubModel(values, property, model.get(this));
            });
            that._values = values;

        }
    });

    function _assignSubModel(container, property, newValue) {

        var parts = property.split('.');

        if (parts.length == 1) {
            container[property] = newValue;
            return;
        }

        if (!container[parts[0]]) {
            container[parts[0]] = {};
        }

        _assignSubModel(container[parts[0]], parts.splice(1).join('.'), newValue);
    }

    function _equal(x, y) {
        if (x === y) {
            return true;
        }

        var xtype = $.type(x), ytype = $.type(y), field;

        if (xtype !== ytype) {
            return false;
        }

        if (xtype === "date") {
            return x.getTime() === y.getTime();
        }

        if (xtype !== "object" && xtype !== "array") {
            return false;
        }

        for (field in x) {
            if (!_equal(x[field], y[field])) {
                return false;
            }
        }

        return true;
    }

    kendo.data.cachedCalculatedProperty = function (func) {
        return new cachedCalculatedProperty(func).value;
    };

    kendo.data.ObservableObject.fn.toClientSide = function (items) {
        var that = this;
        $.each(items, function () {

            var value = that.get(this);

            _assignSubModel(that, this, function () { return value; });
        });
    };

})(jQuery, kendo);
﻿// ==========================================================================
// Copyright (C) 2014 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

(function (k) {

    var oldValue = k.ui.Slider.prototype.value;

    k.ui.Slider.prototype.value = function (value) {
        
        if (value && !isNaN(value)) {
            if (value < this.options.min) {
                value = this.options.min;
            } else if (value > this.options.max) {
                value = this.options.max;
            }
        }

        return oldValue.call(this, value);
    };


    k.ui.Slider.prototype.redraw = function () {

        function _round(value) {
            value = parseFloat(value, 10);
            var power = Math.pow(10, 3 || 0);
            return Math.round(value * power) / power;
        }

        var that = this,
            options = that.options;

        var distance = _round(options.max - options.min);
        if($.isNumeric(distance) && distance > 0) {
            that._distance = _round(options.max - options.min);
            that._createHtml();

            that.resize(true); //will also trigger the refresh.    
        }  
    };
    

    k.override(k.ui.Slider.prototype, "_keydown", function () {
        var returnValue = this.parent.apply(this.context, arguments);
        this.context._setTooltipTimeout();
        
        return returnValue;
    });
    
    k.override(k.ui.Slider.prototype, "_focus", function () {
        var returnValue = this.parent.apply(this.context, arguments);
        this.context._setTooltipTimeout();
        
        return returnValue;
    });

})(kendo);


// ==========================================================================
// Copyright (C) 2017 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

define("genetec/kendo.override.tabstrip", ["jquery", "genetec/widgets/navigation", "genetec/widgets/stylemanager", "genetec/widgets/pagemanager"], function($, navigation, stylemanager, pageManager) {
//Need to have it Settings Page in order to set al
    var module = {};
    var hashParameterName = "tabUrl";
    var contentLoaded = "contentLoaded";
    var helpUrl = "helpUrl";
    module.tabStripManager = "tabStripManager";

    function _tabStripManager(ts) {
        var that = this;
        that.tabStrip = ts;
        that.__handleHashChange = function(e) {
            that._handleHashChange(e);
        };

        navigation.bind(navigation.eventNames.HASH_CHANGED, that.__handleHashChange);

        that.tabStrip.bind("select", that._onTabStripTabSelect);
        that.tabStrip.element.data(module.tabStripManager, that);

        var currentUrl = navigation.getHashParameter(hashParameterName);


    if (currentUrl == null || currentUrl == undefined || currentUrl === "" || $("[aria-controls='" + $("[data-content-url='" + currentUrl + "']").attr("id") + "']").length === 0) {
            navigation.replaceHashParamater(hashParameterName, that.tabStrip.contentHolder(that.tabStrip.select().index()).data('contentUrl'));
        }
        else {
            var tabContentId = $("[data-content-url='" + currentUrl + "']").attr("id");

            that.tabStrip.activateTab($("[aria-controls='" + tabContentId + "']"));
        }
    }
//Handleing hashchnage and updating help URL
    $.extend(_tabStripManager.prototype, {
        destroy: function () {
            var that = this;

            navigation.unbind(navigation.eventNames.HASH_CHANGED, that.__handleHashChange);
        },
        _onTabStripTabSelect: function(e) {
            navigation.setHashParameter(hashParameterName, $(e.contentElement).data('contentUrl'));
        },
        _handleHashChange: function () {
            var url = navigation.getHashParameter(hashParameterName);
            var container = $("[data-content-url='" + url + "']");

            this.tabStrip.activateTab($("[aria-controls='" + container.attr("id") + "']"));

            if (container.data(contentLoaded) === true) {
                pageManager.updateHelpUrl(container.data(helpUrl));

               $(window).resize();
                return;
            }

            navigation.ajax({ url: url }).done(function (data) {
                stylemanager.unloadElements(container);
                navigation.runInit(container, data.view, data);
                stylemanager.loadElements(container);

                container.data(contentLoaded, true);
                container.data(helpUrl, data.helpUrl);

                $(window).resize();
            });
        }
    });

    function _createTabStripManager(tabStrip) {
        return new _tabStripManager(tabStrip);
    }

    module.createTabStripManager = _createTabStripManager;
    return module;
});

﻿// ==========================================================================
// Copyright (C) 2012 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
define("genetec/kendo.upload", [], function () {

    var module = {};

    return module;
});

require(["jquery", "kendo"], function(jQuery, kendo) {

    var oldOnDrop = kendo.ui.Upload.prototype._onDrop;
    
    kendo.ui.Upload.prototype._showHeaderUploadStatus = function(e) {
    };

    kendo.ui.Upload.prototype._wrapInput = function(input) {
        var that = this;
        var options = that.options;

        input.wrap("<div class='k-button k-upload-button'></div>");

        if (!options.async.saveUrl) {
            input.closest(".k-upload").addClass("k-upload-sync");
        }

        input.closest(".k-upload").addClass("k-upload-empty");

        input.closest(".k-button")
            .append("<span>" + this.localization.select + "</span>");

        return input.closest(".k-upload");
    };
    kendo.ui.Upload.prototype._onDrop = function(e) {

        var that = this;
        if (that.trigger('beforeDrop', $.extend({}, e))) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }

        oldOnDrop.apply(that, arguments);
        return;
    };
});
﻿define("genetec/kendo.validation.camera", [], function () {
    return {};
});

require(["jquery", "kendo", "options"], function ($, kendo, gOptions) {

    function _validateOak(input) {

        if (input.filter("[data-val-oak]").length == 0) {
            return true;
        }

        var val = input.val();

        if (val == null || val == "") {
            return true;
        }

        val = val.replace(/-/g, "");

        if (val.length != 12) {
            return false;
        }

        if (/[^abcdef1234567890]/gi.test(val)) {
            return false;
        }

        return true; 
    }

    function _validateMotionDetection(input) {

        if (input.filter("[data-val-motiondetection]").length == 0) {
            return true;
        }

        var val = input.val(),
            recordingType = $("#" + input.attr('data-val-motiondetection-recordingtype')).val();

        if (val == "true") {
            return true;
        }

        recordingType = gOptions.Constants.enums.RecordingType[recordingType];

        if (recordingType == gOptions.Constants.enums.RecordingType.CloudOnMotion || recordingType == gOptions.Constants.enums.RecordingType.SDContinuousCloudOnMotion) {
            return false;
        }

        return true;
    }

    function _validateAxisSerialNumber(input) {

        if (input.filter("[data-val-axisserialnumber]").length == 0) {
            return true;
        }

        var val = input.val();

        if (val == null || val == "") {
            return true;
        }

        if (val.length != 12) {
            return false;
        }

        if (/[^abcdef1234567890]/gi.test(val)) {
            return false;
        }

        return true;
    }

    function _validateMacAddress(input) {
        
        if (input.filter("[data-val-macaddress]").length == 0) {
            return true;
        }

        var val = input.val();

        if (val == null || val == "") {
            return true;
        }

        if (val.length != 12) {
            return false;
        }

        if (/[^abcdef1234567890]/gi.test(val)) {
            return false;
        }

        return true;
    }
    
    function _validateNetworkAttachedStorageModel(input) {

        if (input.filter("[data-val-networkattachedstoragemodel]").length == 0) {
            return true;
        }

        var manufacturerModel = $("#" + input.attr('data-val-networkattachedstoragemodel-manufacturermodel')).val();

        manufacturerModel = gOptions.Constants.enums.NetworkAttachedStorageManufacturerModel[manufacturerModel];

        if (manufacturerModel != gOptions.Constants.enums.NetworkAttachedStorageManufacturerModel.None ||
            manufacturerModel != gOptions.Constants.enums.NetworkAttachedStorageManufacturerModel.Other) {
            return true;
        }
        
        var customModel = $("#" + input.attr('data-val-networkattachedstoragemodel-custommodel')).val();

        if (customModel && customModel.length > 0 && manufacturerModel == gOptions.Constants.enums.NetworkAttachedStorageManufacturerModel.Other) {
            return true;
        }

        return false;
    }

    function _getOakMessage(input) {
        return $(input).attr("data-val-oak");
    }

    function _getAxisSerialNumberMessage(input) {
        return $(input).attr("data-val-axisserialnumber");
    }

    function _getMotionDetectionMessage(input) {
        return $(input).attr("data-val-motiondetection");
    }
    
    function _getNetworkAttachedStorageModel(input) {
        return $(input).attr("data-val-networkattachedstoragemodel");
    }

    function _getMacAddressMessage(input) {
        return $(input).attr("data-val-macaddress");
    }

    $.extend(kendo.ui.validator.rules, {
        oak: _validateOak,
        axisserialnumber: _validateAxisSerialNumber,
        motiondetection: _validateMotionDetection,
        networkAttachedStorageModel: _validateNetworkAttachedStorageModel,
        macaddress: _validateMacAddress
    });

    $.extend(kendo.ui.validator.messages, {
        oak: _getOakMessage,
        axisserialnumber: _getAxisSerialNumberMessage,
        motiondetection: _getMotionDetectionMessage,
        networkAttachedStorageModel: _getNetworkAttachedStorageModel,
        macaddress: _getMacAddressMessage
    });
});
﻿define("genetec/kendo.validation", ["jquery", "kendo", "genetec/widgets/messagebox"], function ($, kendo, messageBox) {

    //Need for Edit Device
    function _applyServerSideValidationErrors(container, args) {
        var errors = args.Errors;

        if (errors === undefined || errors == null) {

            errors = args.errors;

            if (errors === undefined || errors == null) {
                return true;
            }
        }

        var fieldlessErrorMessage;

        for (var errorName in errors) {

            var errorMessage = errors[errorName].errors[0];
            errorMessage = kendo.format(errorMessage, $("label[for='" + errorName + "']").html());

            var htmlElement = $("[name='" + errorName + "']", container);
            if (errorName != "" && htmlElement.length > 0) {
                htmlElement.addServerSideError(errorMessage);
            }
            else {
                fieldlessErrorMessage = errorMessage;
            }
        }

        if (fieldlessErrorMessage) {
            messageBox.show({ content: fieldlessErrorMessage });
        }

        return false;
    }

    return {
        applyServerSideError: _applyServerSideValidationErrors
    };
});

require(["jquery", "kendo"], function ($, kendo) {
    //Need to have it when we copy paste with mouse in the username when EmailAddress is supposed to be the same userName
    $(document).ready(function () {
        $(document).on("cut", function (e) { setTimeout(function () { $(e.target).trigger("cutted"); }, 0); });
        $(document).on("paste", function (e) { setTimeout(function () { $(e.target).trigger("pasted"); }, 0); });
    });
    
    function _addServerSideError(message) {

        return $(this).each(function () {
            $.data(this, serverSideValidationDataKey, message);

            $(this).parents(':hasData(kendoValidator)').data('kendoValidator').validateInput(this);
        });
    }
    
    function _trimString(str) {
        if (str) {
            return str.replace(/^\s+|\s+$/g, '');
        }
        return null;
    }

    function _validateRequired(input, rule) {

        if (input.filter("[data-val-required]").length || (input.filter("[data-val-requiredif]").length && rule == "requiredif")) {
            var value = _trimString(input.val()),
                checkbox = input.filter("[type=checkbox]");

            if (checkbox.length) {
                var hidden = checkbox.next("input:hidden[name=" + checkbox[0].name + "]");
                if (hidden.length) {
                    value = hidden.val();
                } else {
                    value = input.attr("checked") === "checked";
                }
            }

            return !(value === "" || !value || (!input.is(":text") && value == 0));
        }
        return true;
    }

    function _bindValidationMoment() {

        $.each(this, function () {
            var that = $(this);
            var validator = that.data('kendoValidator') || parents(':hasData(kendoValidator)').data("kendoValidator");

            that.find(":input").each(function () {
                var input = $(this);
                    input.on(input.attr("data-value-update") || "blur", function () {
                        validator.validateInput(this);
                    });           
            });
        });

        return this;
    }

    var serverSideValidationDataKey = "serverSideValidation";
    var serverSideValidation = {
        message: function(input) {
            if (input.hasClass("input-validation-error")) {
                input.removeClass("input-validation-error");
                var validationSpan = input.siblings(".field-validation-error");
                var html = validationSpan.html();

                validationSpan.remove();
                return html;
            }

            var message = input.data(serverSideValidationDataKey);
            input.data(serverSideValidationDataKey, null);

            return message;
        },
        rule: function(input) {
            var that = $(input);

            if (that.not('.k-invalid-msg').hasClass("input-validation-error")) {
                return false;
            }

            return !input.is(":hasData(" + serverSideValidationDataKey + ")");
        }
    };

    var maximum =
    {
        message: function (input) {
            var max;
            if (input.filter("[data-val-maximum-dependentproperty]").length) {
                var dependentProperty = input.attr("data-val-maximum-dependentproperty");
                max = input.get(0).kendoBindingTarget.source.get(dependentProperty);
            } else {
                max = parseInt(input.attr("data-val-maximum-value"));
            }
            return kendo.format(input.data("valMaximum"), max);
        },

        rule: function (input) {
            if (input.filter("[data-val-maximum]").length) {
                if (input.val() === "")
                    return true;

                var max;
                if (input.filter("[data-val-maximum-dependentproperty]").length) {
                    var dependentProperty = input.attr("data-val-maximum-dependentproperty");
                    max = input.get(0).kendoBindingTarget.source.get(dependentProperty);
                } else {
                    max = parseInt(input.attr("data-val-maximum-value"));
                }
                return parseInt(input.val()) <= max;
            }
            return true;
        }
    };

    var minimum =
    {
        message: function (input) {
            var min;
            if (input.filter("[data-val-minimum-dependentproperty]").length) {
                var dependentProperty = input.attr("data-val-minimum-dependentproperty");
                min = parseInt(input.get(0).kendoBindingTarget.source.get(dependentProperty));
            } else {
                min = parseInt(input.attr("data-val-minimum-value"));
            }
            return kendo.format(input.data("valMinimum"), min);
        },

        rule: function (input) {
            if (input.filter("[data-val-minimum]").length) {
                if (input.val() === "")
                    return true;

                var min;
                if (input.filter("[data-val-minimum-dependentproperty]").length) {
                    var dependentProperty = input.attr("data-val-minimum-dependentproperty");
                    min = parseInt(input.get(0).kendoBindingTarget.source.get(dependentProperty));
                } else {
                    min = parseInt(input.attr("data-val-minimum-value"));
                }
                return parseInt(input.val()) >= min;
            }
            return true;
        }
    };

    var countryEntityRequirediIf = {
        //in EndUserAccounts /Edit after changing country from CA or US to sth else and return it back to US or CA must have a proper validation 
        message: function(input) {
            var message = input.data("valCountryentityrequiredif");
            return message;
        },
        rule: function(input) {
            if (input.filter("[data-val-countryentityrequiredif]").length && input.is(":visible")) {

                var targetValue = input.attr("data-val-countryentityrequiredif-countryentityid");
                var value = input.val();
                if (value == targetValue)
                    return false;
            }
            return true;
        }
    };

    var equalTo = {
        message: function(input) {
            var message = input.data("valEqualto");
            return message;
        },
        rule: function(input) {
            if (input.filter("[data-val-equalto-other]").length) {
                var otherField = input.attr("data-val-equalto-other");
                otherField = otherField.substr(otherField.lastIndexOf(".") + 1);

                return input.val() == $("#" + otherField).val();
            }
            return true;
        }
    };

    var minCheckboxes = {
        message: function (input) {
            var message = input.data("valMincheckboxes");
            return message;
        },
        rule: function (input) {
            if (input.filter("[data-val-mincheckboxes]").length) {
                var count = $("input[name='" + input.attr("name") + "']:checked");

                if (count.length == 0) {
                    return false;
                }
            }
            return true;
        }
    };

    var invalidChar = {
        //adding validation in  NAS creation page
        message: function (input) {
            var message = input.data("valInvalidchar");
            return message;
        },
        rule: function (input) {
            if (input.filter("[data-val-invalidchar]").length == 0) {
                return true;
            }

            var val = input.val();

            if (val == null || val == "") {
                return true;
            }

            return /^([^<>]*)$/gi.test(val);
        }
    };

    var email = {
        message: function(input) {
            var message = input.data("valEmail");
            return message;
        }
    };

    var multipleEmails = {
        //Multiple Emails filed validation in IntegratorAccounts
        message: function (input) {
                var message = input.data("valMultipleemail");
                return message;
        },
        rule: function (input) {
            if (input.filter("[data-val-multipleemail]").length == 0) {
                return true;
            }

            var val = input.val();
            if (val == null || val == "") {
                return true;
            }

            // regex from kendo.validator.js
            var emailRegExp = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

            var emailsAreValid = true;
            var emailList = val.split(';');
            emailList.forEach(function (email) {

                if (!emailRegExp.test(email)) {
                    emailsAreValid = false;
                    return false;
                }

            });

            return emailsAreValid;
        }
    };

    var numericFormat = {
        // Numeric validation in NAS
        message: function (input) {
            var message = input.data("valNumericformat");
            return message;
        },
        rule: function (input) {
            if (input.filter("[data-val-numericformat]").length == 0) {
                return true;
            }

            var val = input.val().trim().replace(',', '.'),
                allowEquality = input.attr('data-val-numericformat-allowequality') === "true",
                lowerLimit = parseInt(input.attr('data-val-numericformat-lowerlimit')),
                greaterLimit = parseInt(input.attr('data-val-numericformat-greaterlimit')),
                numberFormat = input.attr('data-val-numericformat-numberformat');

            if (val == null || val == "") {
                return true;
            }

            var regex = new RegExp(numberFormat, "gi");

            if (regex.test(val)) {

                if ((allowEquality && val == lowerLimit) ||
                    (allowEquality && val == greaterLimit)) {
                    return true;
                }

                if (val > lowerLimit && val < greaterLimit) {
                    return true;
                }
            }

            return false;
        }
    };

    var mobileAuthenticationKey = {
        // Refuse null values and special characters for mobile authentication key
        message: function (input) {
            var message = input.data("valMobileauthenticationkey");
            return message;
        },
        rule: function (input) {
            if (input.filter("[data-val-mobileauthenticationkey]").length) {
                if (input.is(':visible')) { // Should check IsNullDeviceAuthenticationKeyRefused instead.

                    var acceptedKeyPattern = RegExp(input.attr("data-val-mobileauthenticationkey-acceptedkeypattern"));
                    if (input.val() === "" || !acceptedKeyPattern.test(input.val())
                        || input.val().length < input.attr("data-val-mobileauthenticationkey-minimumkeylength")) {
                        return false;
                    }
                }
            }
            return true;
        }
    };

    var integer = {
        message: function (input){
            return input.data("valInteger");
        },
        rule: function(input) {
            if (input.filter("[data-val-integer]").length == 0) {
                return true;
            }

            var val = input.val();
            if (val == null || val == "") {
                return true;
            }

            var regEx = /^\d+$/;
            return regEx.test(val);
        }
    }

    var required = {
        message: function _getRequiredValidationMessage(input) {
            var message = input.data("valRequired");
            return message;
        }
    }

    var requiredIf = {
        //The requiredif validation is more of a => if (dependentproperty == target) then check if it's required 
        message: function (input) {
            var message = input.data("valRequiredif");
            return message;
        },
        rule: function (input) {
            if (input.filter("[data-val-requiredif]").length) {
                var dependentProperty = input.attr("data-val-requiredif-dependentproperty");
                dependentProperty = dependentProperty.substr(dependentProperty.lastIndexOf(".") + 1);

                var targetValues = input.attr("data-val-requiredif-targetvalue").split(';');
                var property = $("#" + dependentProperty);

                var currentValue = property.filter("[type=checkbox]").length ? property.is(':checked').toString() : property.val();
                if (targetValues.indexOf(currentValue) > -1) {
                    return this.options.rules["required"](input, "requiredif");
                }
            }
            return true;
        }
    };

    var requiredIfValue = {
        //The requiredifvalue validation is more of a => if (dependentproperty == target) then it's required.
        message: function (input) {
            var message = input.data("valRequiredifvalue");
            return message;
        },
        rule: function (input) {
            if (input.filter("[data-val-requiredifvalue]").length) {
                var currentValue;
                var dependentProperty = input.attr("data-val-requiredifvalue-dependentproperty");
                dependentProperty = dependentProperty.substr(dependentProperty.lastIndexOf(".") + 1);

                var targetValue = input.attr("data-val-requiredifvalue-targetvalue");
                var property = $("#" + dependentProperty);

                currentValue = property.val();
                return (currentValue != targetValue);
            }

            return true;
        }
    };

    var confirmValue = {
        message: function (input) {
            var message = input.data("valConfirmvalue");
            return message;
        },
        rule: function (input) {
            if (input.filter("[data-val-confirmvalue]").length) {

                var targetValue = input.attr("data-val-confirmvalue-targetvalue");

                return (input.val() === targetValue);
            }

            return true;
        }
    };

    $.extend(kendo.ui.Validator.prototype.options.rules, {
        required: _validateRequired
    });

    $.extend(kendo.ui.validator.rules, {
        serverSide: serverSideValidation.rule,
        countryentityrequiredif: countryEntityRequirediIf.rule,
        equalto: equalTo.rule,
        mincheckboxes: minCheckboxes.rule,        
        invalidchar: invalidChar.rule,
        multipleemail: multipleEmails.rule,
        numericformat: numericFormat.rule,
        mobileauthenticationkey: mobileAuthenticationKey.rule,
        requiredif: requiredIf.rule,
        requiredifvalue: requiredIfValue.rule,
        minimum: minimum.rule,
        maximum: maximum.rule,
        mvcrequired: _validateRequired,
        integer: integer.rule,
        confirmValue: confirmValue.rule
    });

    $.extend(kendo.ui.validator.messages, {
        serverSide: serverSideValidation.message,
        countryentityrequiredif: countryEntityRequirediIf.message,
        email: email.message,
        multipleemail: multipleEmails.message,
        invalidchar: invalidChar.message,
        required: required.message,
        requiredif: requiredIf.message,
        requiredifvalue: requiredIfValue.message,
        mobileauthenticationkey: mobileAuthenticationKey.message,
        equalto: equalTo.message,
        numericformat: numericFormat.message,
        mincheckboxes: minCheckboxes.message,
        minimum: minimum.message,
        maximum: maximum.message,
        integer: integer.message,
        confirmValue: confirmValue.message
    });

    //Need in IntegratorAdministrator/Edit 
    $.extend($.fn, {
        addServerSideError: _addServerSideError,
        bindValidationMoment: _bindValidationMoment
    });

    //var oldCheckValidity = kendo.ui.validator._checkValidity;
    var oldCheckValidity = kendo.ui.Validator.prototype._checkValidity;

    kendo.ui.Validator.prototype._checkValidity = function (input) {

        var that = this;

        if (that.options.disabled == true) {
            return { valid: true };
        }

        if (input.hasClass("ignore-val")) {
            return { valid: true };
        }

        return oldCheckValidity.apply(this, arguments);
    };

});
﻿// ==========================================================================
// Copyright (C) 2012 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
define("genetec/kendo.window", [], function () {

    var module = {};

    return module;
});


require(["jquery", "kendo"], function (jQuery, kendo) {
    
    var oldOpen = kendo.ui.Window.prototype.open;
    
    var oldClose = kendo.ui.Window.prototype._close;
    
    kendo.ui.Window.prototype.open = function () {
        if (this.options.modal) {
            var overlay = this._overlay(true);
            overlay.kendoStop();
        }
        
        var returnValue = oldOpen.apply(this, arguments);

        _setTabIndex(':input:not(.k-window :input), a:not(.k-window a), :focusable:not(.k-window :focusable)', -1);

        $(document).on('keydown.preventBackSpace', function (e) {
            if (e.keyCode == 8 && !$(e.target).is(":input")) {
                e.preventDefault();
            }
        });

        return returnValue;
    };

    // kendo.ui.Window.prototype.close() is used for non userTriggered closing.
    // MessageBox.js uses ._close() directly.
    kendo.ui.Window.prototype._close = function () {

        $(".k-window :focusable ").off("blur.focusOff");

        $(document).off('keydown.preventBackSpace');

        _revertTabIndex();
        return oldClose.apply(this, arguments);
    };

    function _setTabIndex(selector, value) {
        $(selector).each(function () {
            var elmt = $(this);
            
            if (elmt.data() == undefined || elmt.data().tab_index || elmt.data().tab_index == "") {
                return;
            }
            else if (elmt.attr('tabindex') == null) {
                elmt.data('tab_index', '');
            } else {
                elmt.data('tab_index', elmt.attr('tabindex'));
            }
            elmt.attr('tabindex', value);
        });
    }
    
    
    function _revertTabIndex() {
        $(':hasData(tab_index)').each(function () {
            var elmt = $(this);
            if (elmt.attr('tabindex') == '') {
                elmt.attr('tabindex', null);
            } else {
                elmt.attr('tabindex', elmt.data('tab_index'));
            }
            elmt.removeData('tab_index');
        });
    }
});

﻿// ==========================================================================
// Copyright (C) 2013 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================


(function ($) {

    // shorten references to variables. this is better for uglification var kendo = window.kendo,
    var kendo = window.kendo,
        ui = kendo.ui,
        widget = ui.Widget,
        DATABINDING = "dataBinding",
        DATABOUND = "dataBound",
        ERROR = "error",
        REQUESTEND = 'requestEnd',
        CHANGE = "change";

    var systemHealth = widget.extend({
        init: function(element, options) {
            var that = this;

            widget.fn.init.call(that, element, options);

            var dataSource = new kendo.data.DataSource({
                error: function () {
                    that.element.html(that.options.errorText);
                },
                type: "aspnetmvc-ajax",
                transport: {
                    read: {
                        url: that.options.url,
                        headers: { "g-session-skip-sliding": "true" },
                        dataType: "json"
                    }
                },
                serverSorting: true,
                serverPaging: true,
                page: 1,
                pageSize: that.options.pageSize,
                schema: {
                    data: 'Data',
                },
            });

            require([that.options.displayTemplateKey], function(displayTemplate) {
                that.template = kendo.template(displayTemplate);
                that.setDataSource(dataSource);

                if (that.options.autoRefresh && that.options.autoRefreshTimer > 0) {
                    require(["genetec/widgets/timer"], function(timer) {
                        that.setTimer(timer);
                    });
                }
            });
        },

        setTimer: function (timer) {

            var that = this;
            
            that._Timer = timer.create({
                Callback: function () {
                    that.dataSource.fetch();
                },
                Timeout: that.options.autoRefreshTimer,
                AutoRepeat: true
            });
            
        },
        
        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget). 
            // The jQuery plugin would be jQuery.fn.kendoMyWidget.
            name: 'SystemHealth',
            
            // other options go here
            autoBind: true,
            displayTemplateKey: 'genetec/require.template!Views_HealthDashboard_DisplayTemplates_SystemOverviewModel',
            pageSize: 5,
            autoRefresh: true,
            autoRefreshTimer: 120000,
            noDataRowText: '',
            errorText:'',
            url: '',
        },
        
        // events are used by other widgets / developers - API for other purposes
        // these events support MVVM bound items in the template. for loose coupling with MVVM.
        events: [
            // call before mutating DOM.
            // mvvm will traverse DOM, unbind any bound elements or widgets
            DATABINDING,
            // call after mutating DOM
            // traverses DOM and binds ALL THE THINGS
            DATABOUND
        ],

        // mvvm expects an array of dom elements that represent each item of the datasource.
        // should be the outermost element's children
        items: function() {
            return this.element.children();
        },
        
        setDataSource: function (dataSource) {
            // set the internal datasource equal to the one passed in by MVVM
            this.options.dataSource = dataSource;
            // rebuild the datasource if necessary, or just reassign
            this._dataSource();
        },
        
        refresh: function() {
            var that = this,
                view = that.dataSource.view(),
                html = kendo.render(that.template, view);

            // trigger the dataBinding event
            that.trigger(DATABINDING);

            // mutate the DOM (AKA build the widget UI)
            that.element.html(html);
            
            $("[data-role=grid]", that.element.html).data('roleOptions', {
                //Inject options to the Grid here
                dataBound: that._onGridDataBound,
                noRowDataText: that.options.noDataRowText,
                errorText: that.options.errorText,
            });

            //bind the widget content by uid
            $("[data-uid]", that.element).each(function () {
                kendo.bind(this, that.dataSource.getByUid($(this).data("uid")));
            });
            
            //bind the widget view to the page element
            kendo.bind(that.element, view);
            
            // trigger the dataBound event
            that.trigger(DATABOUND);
        },
        
        _onGridDataBound: function (e) {
            var grid = this;

            if (grid.dataSource.total() <= 0) {

                var columnCount = grid.columns.length;

                $(grid.tbody.get(0)).append(
                    kendo.format("<tr><td colspan='{0}'>{1}</td></tr>", columnCount, e.sender.options.noRowDataText));
            }
        },
       
        _startAutoRefresh: function () {
            if (this._Timer) {
                this._Timer.Start();
            }
        },

        _stopAutoRefresh: function () {
            if (this._Timer) {
                this._Timer.Cancel();
            }
        },
        
        _dataSource: function () {
            var that = this;

            // if the DataSource is defined and the _refreshHandler is wired up, unbind because
            // we need to rebuild the DataSource
            if (that.dataSource && that._refreshHandler) {
                that.dataSource.unbind(CHANGE, that._refreshHandler);
            }
            else {
                that._refreshHandler = $.proxy(that.refresh, that);
            }
            
            that._startRefreshHandler = $.proxy(that._startAutoRefresh, that);
            that._stopRefreshHandler = $.proxy(that._stopAutoRefresh, that);

            // returns the datasource OR creates one if using array or configuration object
            that.dataSource = kendo.data.DataSource.create(that.options.dataSource);
            // bind to the change event to refresh the widget
            that.dataSource.bind(CHANGE, that._refreshHandler);
            
            if (that.options.autoRefresh) {
                that.dataSource.bind(REQUESTEND, that._startRefreshHandler);
                that.dataSource.bind(ERROR, that._stopRefreshHandler);
            }

            if (that.options.autoBind) {
                that.dataSource.fetch();
            }
        }
    });

    ui.plugin(systemHealth);

})(jQuery);
﻿// ==========================================================================
// Copyright (C) 2014 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

(function ($, k) {

    // shorten references to variables. this is better for uglification var kendo = window.kendo,
    var kendo = k,
        ui = kendo.ui,
        widget = ui.Widget;

    var barSectionClassColorPalette = ["#4CFFBA", "#44D5E8", "#5298FF", "#5145E8", "#AF46FF"];

    var Item = kendo.data.ObservableObject.extend({
        init: function (outerItem, index, propertyMappings, colorPalette, showEntities) {
            var that = this;

            kendo.data.ObservableObject.fn.init.call(that);

            that.item = outerItem;
            that.index = index;
            that._propertyMappings = propertyMappings;
            that._colorPalette = colorPalette;
            that._showEntities = showEntities;
        },
        color: function () {

            var color = undefined;

            if (this.item && $.isFunction(this.item.color)) {
                color = this.item.color();
            }

            return color || this._colorPalette[this.get("index") % this._colorPalette.length];
        },
        onHover: function (e) {
            if (!this._showEntities) {
                return;
            }

            this.set("isHover", e.type == "mouseenter");
        },
        entityName: function () {
            return this.get("item." + this._propertyMappings.itemName);
        },
        entityUrl: function () {
            return this.get("item." + this._propertyMappings.itemUrl);
        },
        getEntityStyles: function () {
            var css = {
                "width": "13px",
                "height": "13px",
                "border": "1px solid #362F2D",
                "border-radius": "2px",
                "display": "inline-block",
                "vertical-align": "middle",
                "-webkit-box-shadow": "",
                "box-shadow": "",
                "overflow": "hidden",
            };

            if (this.get("isHover")) {
                $.extend(css, {
                    "-webkit-box-shadow": "0 0 5px 1px #362F2D inset",
                    "box-shadow": "0 0 5px 1px #362F2D inset",
                });
            }

            css["background-Color"] = this.get("color()");

            return css;
        },
        getStyles: function () {

            var totalWeight = this.get("totalWeight"),
                itemWeight = this.get("item." + this._propertyMappings.itemWeight),
                totalUsed = this.get("totalUsed"),
                modifier = 1;

            this.get("index");

            if (totalUsed > totalWeight) {
                modifier = (totalWeight / totalUsed).toPrecision();
            }

            var css = {
                "background-color": this.get("color()"),
                "float": "left",
                "-webkit-transition": "width 1s",
                "-moz-transition": "width 1s",
                "-o-transition": "width 1s",
                "transition": "width 1s",
                "height": "100%",
                "width": itemWeight * 100 / totalWeight * modifier + "%",
                "-webkit-box-shadow": "",
                "box-shadow": "",
                "overflow": "hidden",
            };

            if (this.get("isHover")) {
                $.extend(css, {
                    "-webkit-box-shadow": "0 0 5px 1px #362F2D inset",
                    "box-shadow": "0 0 5px 1px #362F2D inset",
                });
            }

            return css;
        }
    });

    var InnerModel = kendo.data.ObservableObject.extend({
        init: function (propertyMappings, styles, strings, showEntities) {
            var that = this;
            that.showEntities = showEntities;

            kendo.data.ObservableObject.fn.init.call(that, { outerModel: undefined, items: [] });

            that._propertyMappings = propertyMappings;
            that._styles = styles;
            that._strings = strings;

            that.bind("change", that._onChange);

            that.items.bind("change", that._onItemsChange);
        },
        _onItemsChange: function (e) {

            var that = this;

            if (e.action == "add") {

                $.each(e.items || [e.item], function () {
                    var oneItem = kendo.template("<div data-widget-bind='css: getStyles, events: { mouseenter: onHover, mouseleave: onHover }'></div>")(this),
                        $oneItem = $(oneItem),
                        $items = $($oneItem);

                    kendo.widgetBind($oneItem, this);

                    that.parent().parent().itemsContainer.append($oneItem);

                    var oneEntity = kendo.template('<div data-widget-bind="events: { mouseenter: onHover, mouseleave: onHover }">' +
                            '<div data-widget-bind="css: getEntityStyles,style: {background-color: color}" ></div>' +
                            '<div style="display: inline-block;vertical-align:middle;margin-left:5px;">' +
                            '<a data-widget-bind="attr : { id: entityName, href : entityUrl }, html: entityName"></a>' +
                            '</div>' +
                            '</div>')(this),
                        $oneEntity = $(oneEntity);

                    kendo.widgetBind($oneEntity.find("[data-widget-bind]").andSelf("[data-widget-bind]"), this);

                    $items = $items.add($oneEntity);

                    this.element = function () {
                        return $items;
                    }

                    that.parent().parent().entityContainer.append($oneEntity);
                });
            }
            else if (e.action == "remove") {
                $.each(e.items || [e.item], function () {
                    this.unbind("change", that._onItemsChange);
                    this.element().detach();
                });
            }
        },
        _onChange: function (e) {
            var that = this;

            if (e.syncItemsDone === true || e.field.indexOf("._events") >= 0) {
                    return;
            }

            if (e.field == "outerModel." + that._propertyMappings.items) {
                e.syncItemsDone = true;

                that.parent().syncItems();
            }
            else if (e.field.indexOf("outerModel") == 0) {
                e.syncItemsDone = true;

                that.parent().syncItems();
            }
        },
        isOverCapacity: function () {
            var that = this,
               usedSpace = that.get("totalUsed"),
               total = that.get("totalWeight"),
               unusedSpace = total.call(that) - usedSpace.call(that);

            if (!$.isNumeric(unusedSpace)) {
                return false;
            }

            return unusedSpace < 0;
        },
        entityId: function() {
            var that = this;
            
            return that.get("outerModel." + that._propertyMappings.entityId);
        },
        allocatedId: function() {
            var itemId = this.entityId();

            if (itemId) {
                return itemId + "_allocated";
            }

            return "allocated";
        },
        isOverCapacityId: function() {
            var itemId = this.entityId();

            if (itemId) {
                return itemId + "_isOverCapacity";
            }

            return "isOverCapacity";
        },
        unusedId: function() {
            var itemId = this.entityId();

            if (itemId) {
                return itemId + "_unused";
            }

            return "unused";
        },
        totalUsed: function () {
            var that = this,
                items = that.get("outerModel." + that._propertyMappings.items);

            if (typeof items === "undefined" || items == null || !items.map) {
                return 0;
            }

            return Array.sum(items.map(function (item) {
                if (!item) {
                    return 0;
                }

                return item.get(that._propertyMappings.itemWeight);
            }));
        },
        usedText: function () {
            var that = this,
                totalUsed = that.get("totalUsed()");

            if (typeof totalUsed === "undefined" || totalUsed == null || $.isNumeric(totalUsed) == false) {
                return "";
            }

            return kendo.format(that._strings.reservedSpaceTextFormat, totalUsed.toFixed(2), that._strings.storageUnitText);
        },
        unusedText: function () {
            var that = this,
                usedSpace = that.get("totalUsed"),
                total = that.get("totalWeight"),
                unusedSpace = total.call(that) - usedSpace.call(that);

            if (!$.isNumeric(unusedSpace)) {
                return "";
            }

            if (unusedSpace < 0) {
                unusedSpace = 0;
            }

            return kendo.format(that._strings.unusedSpaceTextFormat, unusedSpace.toFixed(2), that._strings.storageUnitText);
        },
        totalWeight: function () {
            var that = this;
            var total = that.get("outerModel." + that._propertyMappings.totalWeight);
            
            if (total && !$.isNumeric(total)) {
                total = total.replace(',', '.');
            }
            
            return total;
        },
        itemContainerCss: function () {
            var that = this,
                css = $.extend({}, that._styles.overLimit);

            if (that.isOverCapacity() == false) {
                $.each(css, function (property) {
                    css[property] = "";
                });

                $.extend(css, that._styles.barStyles);
            } else {
                css = $.extend({}, that._styles.barStyles, css);
            }

            return css;
        }
    });

    var usageBar = widget.extend({
        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget).
            // The jQuery plugin would be jQuery.fn.kendoMyWidget.
            name: 'UsageBar',

            styles: {
                colorPalette: barSectionClassColorPalette,
                barStyles: {
                    'background-color': '#FFFFFF',
                    'width': '100%',
                    'height': '13px',
                    'line-height': '13px',
                    'border': '1px solid #362F2D',
                    'border-radius': '3px',
                    "-webkit-transition": "box-shadow 1s",
                    "transition": "box-shadow 1s",
                    "box-sizing": "border-box",
                    "overflow": "hidden",
                },
                overLimit: {
                    "-webkit-box-shadow": "0 0 3px 3px #E82F13",
                    "box-shadow": "0 0 3px 3px #E82F13",
                    "border": "1px solid #E82F13",
                },
                colors: {
                    reserved: "#545454",
                    retention: "#35CEFF",
                }
            },

            propertyMappings: {
                items: "",
                totalWeight: "",
                itemName: "",
                itemWeight: "",
                itemUrl: "",
                entityId: "",
            },

            events: [
            ],

            showEntities: false,

            storageSize: 100, //Size in GB

            strings: {
                reservedSpaceTextFormat: "",
                unusedSpaceTextFormat: "",
                overCapacityText: "",
                storageUnitText: ""
            }


        },

        items: function () {
            return $(".bar", this.element).children();
        },

        init: function (element, options) {

            var that = this;

            widget.fn.init.call(that, element, options);

            if (options.styles && options.styles.colorPalette && options.styles.colorPalette.length) {
                that.options.styles.colorPalette = options.styles.colorPalette;
            }

            that._setDataSource();

            that.entityContainer = $('<div data-widget-bind="visible: showEntities"/>');
            kendo.widgetBind(that.entityContainer, that._innerModel);

            that.itemsContainer = $("<div />").css(that.options.styles.barStyles);
            that.itemsContainer.attr("data-widget-bind", "css: itemContainerCss");
            kendo.widgetBind(that.itemsContainer, that._innerModel);

            var $detailsBar = $('<div style="display:table;width: 100%">'
                + '<div data-widget-bind="attr: {id: allocatedId }, html: usedText" style="font-size: 11px;text-align: left;display:table-cell"></div>'
                + '<div data-widget-bind="attr: {id: isOverCapacityId }, visible: isOverCapacity" style="font-size: 11px;font-size: 11px;display:table-cell;text-align: center">' + that.options.strings.overCapacityText + '</div>'
                + '<div data-widget-bind="attr: {id: unusedId }, html: unusedText" style="font-size: 11px;text-align: right;display:table-cell"></div>'
                + '</div>');

            that.element.append(that.entityContainer);
            that.element.append(that.itemsContainer);
            that.element.append($detailsBar);

            kendo.widgetBind($detailsBar.find("[data-widget-bind]"), that._innerModel);
        },

        _setDataSource: function () {
            var that = this;

            that._innerModel = new InnerModel(that.options.propertyMappings, that.options.styles, that.options.strings, that.options.showEntities);
            that._innerModel.parent = function () {
                return that;
            }
            that.dataSource = new kendo.data.DataSource();
        },
        setDataSource: function (value) {
            var that = this;

            if ($.isFunction(value) == true) {
                value = value();
            }

            if (that._innerModel.outerModel == value) {
                return;
            }

            that._innerModel.set("outerModel", value);
            that.syncItems();

            that._innerModel.outerModel.bind("change", function (e) {

                if (e.syncItemsDone === true || e.field.indexOf("_events") >= 0) {
                    return;
                }

                e.syncItemsDone = true;
                that.syncItems();
            });
        },
        syncItems: function() {

            var that = this,
                outerItems = that._innerModel.outerModel.get(that.options.propertyMappings.items),
                innerItems = that._innerModel.items,
                itemsToDelete = innerItems.filter(function(item) {
                    return outerItems.indexOf(item.item) === -1;
                }),
                itemsToAdd = (outerItems || []).filter(function(item) {
                    return typeof innerItems.find(function(item2) {
                        return item == item2.item;
                    }) === "undefined";
                }),
                totalWeight = that._innerModel.totalWeight(),
                totalUsed = that._innerModel.totalUsed();

            itemsToDelete.forEach(function(item) {
                innerItems.remove(item);
            });

            itemsToAdd.forEach(function(item) {

                var index = outerItems.indexOf(item),
                    innerItem = new Item(item, index, that.options.propertyMappings, that.options.styles.colorPalette, that.options.showEntities);

                innerItems.splice(index, 0, innerItem);
            });

            innerItems.forEach(function (item, index) {
                item.set("index", index);
                item.set("totalWeight", totalWeight);
                item.set("totalUsed", totalUsed);
            });
        },
    });

    ui.plugin(usageBar);
})(jQuery, window.kendo);
﻿// ==========================================================================
// Copyright (C) 2017 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================

(function ($) {

    // shorten references to variables. this is better for uglification var kendo = window.kendo,
    var kendo = window.kendo,
        ui = kendo.ui,
        widget = ui.Widget,
        proxy = $.proxy,
        waitingStepTemplateKey = "genetec/require.template!Views_Shared_Widgets_WizardWaitingStep";

    require([waitingStepTemplateKey]);

    var wizard2 = widget.extend({
        init: function (element, options) {
            var that = this;

            require(["options"], function (gOptions) {
                widget.fn.init.call(that, element, $.extend({}, options, gOptions));
            });

            that._createState();

            var steps = that.element.find(".step");
            steps.detach();

            that._createLayout();

            that.state.appendSteps(steps);

            $('.stepContainer',that.element).watch({
                properties: "width,height,display",
                callback: function () {

                    // If the window width is less than a certain threshold, maximize the wizard window when it's opened
                    if ($(window).width() <= 812) {
                        setTimeout(function () {
                                $(that.element).closest('.k-window-content').data('kendoWindow').maximize();
                            },
                            0);
                    } else {
                        $(that.element).closest(".k-window-content").data('kendoWindow').center();
                    }
                },
                watchChildren: true
            });

            //The reason for a settimeout here is that we want to select the first step after widget init and the only reliable way of
            //doing it is to timeout in 0 (pushing the function at the end of the stack)
            setTimeout(function () {
                that.state.setCurrentStep(that.state.steps[0]);
            }, 0);
        },
        _createState: function () {
            var that = this;
            that.state = kendo.observable({
                steps: [],
                _isFinishEnabled: true,
                disableFinish: function () {
                    this.set("_isFinishEnabled", false);
                },
                enableFinish: function () {
                    this.set("_isFinishEnabled", true);
                },
                isFinishEnabled: function () {
                    var that = this,
                        isFinishEnabled = that.get("_isFinishEnabled");

                    that.get("steps");

                    return isFinishEnabled && that._isAllStepsVisited();
                },
                _isAllStepsVisited: function () {
                    var that = this,
                        allStepsVisited = true,
                        currentStep = that.get("currentStep"),
                        visitedSteps = that.steps.filterVisitedSteps(),
                        enabledSteps = that.steps.filterEnabledSteps();

                    //If there's no more steps, means that we are at the end of the wizard
                    if (enabledSteps[enabledSteps.length - 1] === currentStep) {
                        return true;
                    }

                    //Also, if we have visited all the steps currently enabled, means that we have visited all of the wizard steps.
                    $.each(enabledSteps, function (index) {
                        if (visitedSteps.indexOf(enabledSteps[index]) < 0) {
                            allStepsVisited = false;
                        }
                    });

                    return allStepsVisited;
                },
                finish: function () {
                    if (!this.isFinishEnabled()) {
                        return;
                    }

                    if (!$(this.currentStep.body).kContainerIsValid()) {
                        return;
                    }

                    this.trigger("finish");

                    that.triggerCallback("finish");
                },
                isPreviousEnabled: function () {
                    var that = this,
                        steps = that.get("steps"),
                        enabledSteps = steps.filterVisibleSteps(),
                        currentStep = that.get("currentStep"),
                        stepIndex = enabledSteps.indexOf(currentStep);

                    return currentStep != null && stepIndex > 0 && enabledSteps[stepIndex - 1].get("isEnabled");
                },
                navigatePrevious: function () {

                    var that = this;

                    if (!that.isPreviousEnabled()) {
                        return;
                    }

                    var enabledSteps = that.steps.filterVisibleSteps();

                    that.setCurrentStep(enabledSteps[enabledSteps.indexOf(that.currentStep) - 1]);
                },
                navigateToStep: function (id) {

                    var that = this;

                    var step = that.steps.findById(id);

                    that.setCurrentStep(step);
                },
                isNextEnabled: function () {
                    var that = this,
                        steps = that.get("steps"),
                        enabledSteps = steps.filterVisibleSteps(),
                        currentStep = that.get("currentStep"),
                        stepIndex = enabledSteps.indexOf(currentStep);

                    return currentStep != null && stepIndex < enabledSteps.length - 1 && enabledSteps[stepIndex + 1].get("isEnabled");
                },
                navigateNext: function () {

                    var that = this;

                    if (!that.isNextEnabled()) {
                        return;
                    }

                    if (!$(that.currentStep.body).kContainerIsValid()) {
                        return;
                    }

                    var enabledSteps = that.steps.filterVisibleSteps();

                    that.setCurrentStep(enabledSteps[enabledSteps.indexOf(that.currentStep) + 1]);
                },
                setCurrentStep: function (step, skipCurrentStepValidation) {
                    var that = this;

                    if (typeof step === "string") {
                        step = that.steps.findById(step);
                    }

                    var previousStepIndex = that.steps.indexOf(that.currentStep),
                        newStepIndex = that.steps.indexOf(step),
                        isFowardProgress = previousStepIndex < newStepIndex;

                    if (that.currentStep && isFowardProgress && (!that.currentStep.onBeforeExit() || (skipCurrentStepValidation !== true && !$(that.currentStep.body).kContainerIsValid()))) {
                        return;
                    }

                    that.set("currentStep", step);

                    if (isFowardProgress) {
                        that.currentStep.onBeforeEnter();
                    }
                },

                appendSteps: function (dom) {
                    var that = this,
                        $steps = $(dom);

                    var newSteps = $steps.filter(".step").map(function () {

                        var step = new Step(this);

                        step.stepList = function () {
                            return that.steps;
                        };

                        return step;
                    }).get();

                    that.steps.push.apply(that.steps, newSteps);
                }
            });

            that.state.steps.wizardState = function () { return this.parent(); };
            that.state.steps.bind("change", proxy(that.update, that));
            that.state.steps.filterEnabledSteps = function () {
                return this.filter(function (item) {
                    return item.isEnabled;
                });
            };
            that.state.steps.filterVisibleSteps = function () {
                return this.filter(function (item) {
                    return !item.isHidden;
                });
            };
            that.state.steps.filterVisitedSteps = function () {
                return this.filter(function (item) {
                    return (item.hasBeenCurrentStep && !item.isHidden) || item.isHidden;
                });
            };
            that.state.steps.findById = function (id) {
                return this.find(function (item) {
                    return item.id === id;
                });
            };
        },
        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget).
            // The jQuery plugin would be jQuery.fn.kendoMyWidget.
            name: "Wizard2",

            // other options go here
            autoBind: true
        },

        // events are used by other widgets / developers - API for other purposes
        // these events support MVVM bound items in the template. for loose coupling with MVVM.
        events: [
            "finish"
        ],

        // mvvm expects an array of dom elements that represent each item of the datasource.
        // should be the outermost element's children
        items: function () {
            return this.element.children();
        },

        update: function (e) {

            var that = this;

            if (e.action === "add") {
                $.each(e.items, function () {
                    this.bindEvents();
                    this.attachStep();
                    this.stepNumberTemplate = that.options.Constants.Strings.STE_LABEL_STEP;
                });

                //We might want to refresh the editable if there is one since we added
                //steps that, in theory are going to get bound.
                var editable = this.element.parents(":hasData(kendoEditable):first").data("kendoEditable");

                if (editable) {
                    editable.refresh();
                }

            }

            if (e.action === "remove") {
                $.each(e.items, function () {
                    this.unbindEvents();
                });

            }

            $.each(this.state.steps.filterVisibleSteps(), function (index) {
                this.set("index", index + 1);
            });
        },
        _createLayout: function () {
            var that = this;

            //Not so clean to do it from here, but it's just easy!
            that.element.parents(".k-edit-form-container").find("a.k-button").hide();

            that.element.addClass("wizard");

            that.state.stepContainer = $("<div class='stepContainer'></div>");
            that.element.html($("<div class='content' ></div>").append(that.state.stepContainer));

            var actionBar = $("<div class='actionBar'></div>");

            var createButton = $("<a class='buttonFinish ' data-widget-bind='click: finish, enabled: isFinishEnabled, attr: { tabindex: isFinishEnabled() ? 0 : -1}' ><span class='icon-ok' role='img' aria-label='Ok' />" + that.options.buttons.create + "</a>");
            actionBar.append(createButton);

            var nextButton = $("<a class='buttonNext' data-widget-bind='click: navigateNext, enabled: isNextEnabled, attr: { tabindex: isNextEnabled() ? 0 : -1}' >" + that.options.buttons.next + "<span class='icon-right-open' role='img' aria-label='Next' /></a>");
            actionBar.append(nextButton);

            var previousButton = $("<a class='buttonPrevious' data-widget-bind='click: navigatePrevious, enabled: isPreviousEnabled, attr: { tabindex: isPreviousEnabled() ? 0 : -1}' ><span class='icon-left-open' role='img' aria-label='Previous' />" + that.options.buttons.previous + "</a>");
            actionBar.append(previousButton);

            actionBar.on("keypress", "a", function (e) {
                //13 = enter
                //32 = space
                if (e.keyCode === 13 || e.keyCode === 32) {
                    $(e.target).click();
                };
            });

            that.element.append(actionBar);

            kendo.widgetBind(actionBar.find("[data-widget-bind]"), that.state);
        },
        showFirstPageWithError: function () {
            var that = this;

            setTimeout(function () {
                $.each(that.state.steps, function () {

                    if ($(this.body).has(".k-invalid").length > 0) {

                        that.state.setCurrentStep(this);

                        return false;
                    }

                    return true;
                });
            }, 0);
        },
        triggerCallback: function (name) {
            var that = this;

            $.each(that.options.events, function () {
                var item = this;
                if (item.event !== name) {
                    return;
                }

                require([item.module], function (module) {
                    if ($.isFunction(module[item.method])) {
                        module[item.method].call(that);
                    }
                });
            });
        }
    });

    var Step = kendo.data.ObservableObject.extend({
        init: function (dom) {

            var model = {
                body: dom,
                isHidden: false,
                isEnabled: true,
                index: 0,
                isHeaderVisible: true,
                stepNumberTemplate: '{0}',
                stepNumber: function () {
                    return kendo.format(this.get("stepNumberTemplate"), this.get("index"));
                }
            };

            var $body = $(model.body);
            $body.attr("data-widget-bind", "visible: isCurrentStep");
            $body.wrapInner("<div class='step-content'></div>");

            model.id = $body.attr("id");
            model.title = $body.data("stepTitle");

            var $head = $("<div class='head' id='" + $body.attr("id") + "Head' data-widget-bind='visible: isHeaderVisible' ></div>");

            if (model.title.length > 0) {
                $head.append($("<span class='stepNumber' data-widget-bind='html: stepNumber'></span>"));
                $head.append($("<span class='stepTitle' data-widget-bind='html: title'></span>"));
            }

            $body.prepend($head);

            model.head = $head.get(0);

            kendo.data.Model.fn.init.call(this, model);

            this.bind("change", function (e) {
                if (e.field === "isHidden") {
                    this.attachStep();
                }
                else if (e.field === "isCurrentStep") {
                    if (this.isCurrentStep) {
                        var $body = $(this.body);
                        setTimeout(function () {
                            $body.find(":focusable:first").focus();
                        }, 0);
                    }
                }
            });
        },

        attachStep: function () {
            var $content = $(this.body);
            if (this.isHidden) {
                $content.detach();
                return;
            }

            if (jQuery.contains(document.documentElement, this.body) === false) {
                this.stepList().wizardState().stepContainer.append($content);
                kendo.widgetBind($content.find("[data-widget-bind]").andSelf("[data-widget-bind]"), this);
            }
        },
        bindEvents: function () {

            var that = this;
            that._onWizardChangeHandler = $.proxy(that._onWizardChange, that);
            that.stepList().wizardState().bind("change", that._onWizardChangeHandler);

        },
        unbindEvents: function () {
            var that = this;
            that.stepList().wizardState().unbind("change", that._onWizardChangeHandler);
        },
        _onWizardChange: function (e) {
            if (e.field === "currentStep") {
                this.set("isCurrentStep", e.sender.source.currentStep === this);

                if (this.isCurrentStep) {
                    this.set("hasBeenCurrentStep", true);
                } else {

                    var stepIndex = e.sender.source.steps.filterVisibleSteps().indexOf(this),
                        currentStepIndex = e.sender.source.steps.filterVisibleSteps().indexOf(e.sender.source.currentStep);

                    this.set("isNextStep", stepIndex === currentStepIndex + 1);
                }
            }
        },
        canSetAsCurrentStep: function () {
            var isCurrentStep = this.get("isCurrentStep"),
                isEnabled = this.get("isEnabled"),
                hasBeenCurrentStep = this.get("hasBeenCurrentStep"),
                isNextStep = this.get("isNextStep");

            return !isCurrentStep && isEnabled && (hasBeenCurrentStep || isNextStep);
        },
        disable: function () {
            this.set("isEnabled", false);
        },
        enable: function () {
            this.set("isEnabled", true);
        },
        hide: function () {
            this.set("isHidden", true);
        },
        show: function () {
            this.set("isHidden", false);
        },
        setAsCurrentStep: function () {
            if (!this.get("canSetAsCurrentStep").call(this)) {
                return;
            }

            this.stepList().wizardState().setCurrentStep(this);
        },
        hideHeader: function () {
            this.set("isHeaderVisible", false);
        },
        showHeader: function() {
            this.set("isHeaderVisible", true);
        },
        setTitle: function (value) {
            this.set("title", value);
        },
        replaceContent: function (dom) {
            this.hideLoading();
            this._getStepContainer().html(dom);
        },
        onBeforeExit: function () {
            var that = this;

            $(that.body).trigger("exitStep", [that.stepList().wizardState()]);
            return true;
        },
        onBeforeEnter: function () {
            var that = this;

            $(that.body).trigger("enterStep", [that.stepList().wizardState()]);
            return true;
        },
        showLoading: function () {
            var step = this;
            step._getStepContainer().hide();

            require([waitingStepTemplateKey], function (template) {
                var waitingContainer = $("<div class='waitingContainer'></div>");
                waitingContainer.append(template);

                $(step.body).append(waitingContainer);
            });
        },
        hideLoading: function () {
            var step = this;
            $(step.body).find(".waitingContainer").detach();

            this._getStepContainer().show();
        },
        _getStepContainer: function () {
            var $body = $(this.body);
            return $body.find(".step-content");
        }
    });

    ui.plugin(wizard2);

})(jQuery);

﻿// ==========================================================================
// Copyright (C) 2014 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
//
(function ($) {
    // shorten references to variables. this is better for uglification
    var kendo = window.kendo,
        ui = kendo.ui,
        Widget = ui.Widget,
        CLICK = "click",
        NS = ".userMenu",
        HEADER = ".menuHeader",
        CONTENT = ".menuContent",
        CSSACTIVE = "menuActive",
        TIMER = 150;

    var userMenu = Widget.extend({

        init: function(element, options) {

            var that = this;

            // base call to widget initialization
            Widget.fn.init.call(that, element, options);

            that._header = $(element).find(that.options.headerClass);
            that._content = $(element).find(that.options.contentClass);

            that._documentClickHandler = $.proxy(that._documentClick, that);

            that._header.on(CLICK + NS, $.proxy(that._toggle, that, 0));
            that._content.on(CLICK + NS, $.proxy(that._toggle, that, 500));
        },

        options: {    
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget). 
            name: "UserMenu",
            // other options go here
            headerClass: HEADER,
            contentClass: CONTENT,
            menuActiveClass: CSSACTIVE,
        },

        _toggle: function (delay) {
            var that = this;

            //open up the content.
            that._content.delay(delay).slideToggle(TIMER, function () {
                that._header.toggleClass(that.options.menuActiveClass);
            });
            
            //un/register click event to document
            $(document).off(CLICK + NS).on(CLICK + NS, that._documentClickHandler);
        },

        _hide: function () {
            var that = this;

            //hide the content
            that._content.slideUp(TIMER, function () {
                that._header.removeClass(that.options.menuActiveClass);
            });

            $(document).off(CLICK + NS);
        },

        _documentClick: function (e) {
            var that = this;

            //if click inside the widget, discard
            if ($.contains(that.element[0], e.target)) {

                if ($.contains(that._content[0], e.target)) {
                    that._hide();
                }

                return;
            }

            //if click outside the widget...
            that._hide();
        },

    });

    ui.plugin(userMenu);

})(jQuery);

﻿// ==========================================================================
// Copyright (C) 2014 by Genetec, Inc.
// All rights reserved.
// May be used only in accordance with a valid Source Code License Agreement.
// ==========================================================================
//
(function ($) {
    // shorten references to variables. this is better for uglification
    var kendo = window.kendo,
        ui = kendo.ui,
        Widget = ui.Widget,
        CLICK = "click",
        HEADER = ".deviceMenuButton",
        CONTENT = ".deviceMenu",
        CSSACTIVE = "active",
        TIMER = 300;

    var flyout = Widget.extend({
        init: function (element, options) {

            var that = this;

            // base call to widget initialization
            Widget.fn.init.call(that, element, options);

            that._item = $(element).closest(that.options.parentContainer);

            that._menuId = that.options.itemIdPrefix + "." + that.options.itemId;
            that._header = $(that._item).find(that.options.headerClass);
            that._content = $(that._item).find(that.options.contentClass);

            that._header.on(CLICK + "." + that._menuId, $.proxy(that._toggle, that, 0));
            that._content.find("a").on(CLICK + "." + that._menuId, $.proxy(that._performHide, that, 0));

            if (that.options.persist && JSON.parse(sessionStorage.getItem(that._menuId)) === true) {
                that._visible();
            }
        },

        options: {
            // the name is what it will appear as off the kendo namespace(i.e. kendo.ui.MyWidget). 
            name: "Flyout",
            // other options go here
            parentContainer: ".deviceItem",
            itemId: "item",
            itemIdPrefix: "prefix",
            headerClass: HEADER,
            contentClass: CONTENT,
            menuActiveClass: CSSACTIVE,
            selfCloseDelay: 0, //0 to deactivate the self closing.
            persist: true,
        },

        _toggle: function (delay) {
            var that = this;

            //open up the content.         
            that._content.delay(delay).slideToggle(
            {
                duration: TIMER,
                complete: function () {

                    if (that._timeoutId) {
                        window.clearTimeout(that._timeoutId);
                        that._timeoutId = null;
                    }

                    that._header.toggleClass(that.options.menuActiveClass);

                    if (that.options.persist) {
                        sessionStorage.setItem(that._menuId, that._content.is(':visible'));
                    }

                    if (that.options.selfCloseDelay > 0) {
                        that._timeoutId = setTimeout($.proxy(that._hide, that), that.options.selfCloseDelay);
                    }

                },
                queue: false
            });
        },

        _visible: function () {
            var that = this;

            that._header.addClass(that.options.menuActiveClass);
            that._content.show();

            if (that.options.persist) {
                sessionStorage.setItem(that._menuId, true);
            }

        },

        _hide: function () {
            var that = this;

            if (!that._timeoutId) {
                return;
            }

            window.clearTimeout(that._timeoutId);
            that._timeoutId = null;

            that._performHide();
        },

        _performHide: function () {
            var that = this;

            if (that.options.persist) {
                sessionStorage.removeItem(that._menuId);
            }

            //hide the content
            that._content.slideUp(TIMER, function () {
                that._header.removeClass(that.options.menuActiveClass);
            });
        },

    });

    ui.plugin(flyout);

})(jQuery);
